import React from 'react';
import {graphql} from 'gatsby';
import {Page} from '../components/Page';
import PageHeader from '../components/PageHeader';
import {Markdown} from '../components/Markdown';
import {Helmet} from "react-helmet";

export default function Upload({data: {upload}}) {
	return <Page>
		<Helmet>
			<title>{upload.titleSEO}</title>
			<meta name="description" content={upload.descriptionSEO} />
			{/*			<link rel="canonical" href={'https://www.icit.com.au/services/' + service.slug}  />*/}
		</Helmet>
		<PageHeader>{upload.title}</PageHeader>
		<Markdown>
			{upload.bodyText}
		</Markdown>

		<form action="https://www.dropbox.com/request/yRM2umhjI35DTLuXMWUV">
			<input type="submit" value="Upload files" />
		</form>
	</Page>;
}

export const query = graphql`
    query {
        upload {
        	title
        	bodyText
			titleSEO
			descriptionSEO
        }
    }
`;
